<template>
  <div class="customer-modal">
    <!-- select stores -->
    <SelectStores></SelectStores>
    <!-- overlay -->
    <div
      id="overlay"
      class="overlay"
      @click.stop="toggleSideBar('close')"
    ></div>
    <!-- sidebar -->
    <div id="customer-sidebar" class="customer-sidebar">
      <div class="close-btn" @click="toggleSideBar('close')">X</div>
      <!-- logo -->
      <div class="logo">
        <img src="@/assets/website-use/logo_word.png" alt="" />
      </div>
      <hr />
      <ul class="sidebar-list">
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '個人頁面' }"
          @click="go('個人頁面')"
        >
          <img src="@/assets/icon/comment-user.png" alt="" />
          個人頁面
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '賣場總覽' }"
          @click="go('賣場總覽')"
        >
          <img src="@/assets/icon/home.png" alt="" />
          賣場總覽
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '個人賣場' }"
          @click="go('個人賣場')"
        >
          <img src="@/assets/icon/shopping-cart.png" alt="" />
          個人賣場
        </li>
        <li
          class="mb-2 pointer position-relative"
          :class="{ active: currentPage === '我的訂單' }"
          @click="go('我的訂單')"
        >
          <span
            class="tw-tips-badge position-absolute tw-end5"
            :class="
              myOrderQuantity.count > 0
                ? 'tw-tips-badge-danger'
                : 'tw-tips-badge-secondary'
            "
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-if="myOrderQuantity.loading"
            ></span>
            <template v-else>
              {{ myOrderQuantity.count }}
            </template>
          </span>
          <img src="@/assets/icon/edit.png" alt="" />
          我的訂單
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '帳單狀態' }"
        >
          <p class="mb-3 hover" @click="go('帳單狀態')">
            <img src="@/assets/icon/exclamation.png" alt="" />
            帳單狀態<i class="bi bi-caret-down-fill"></i>
          </p>
          <div
            class="sidebar-second-class"
            :class="{ show: showWhichSecondClass === 'C_Bill' }"
          >
            <ul>
              <li
                class="pointer hover position-relative"
                :class="{ active: currentPage === '待付款待出貨' }"
                @click="go('待付款待出貨')"
              >
                <span
                  class="tw-tips-badge position-absolute tw-end5"
                  :class="
                    unpaidAndUnshipped.count > 0
                      ? 'tw-tips-badge-danger'
                      : 'tw-tips-badge-secondary'
                  "
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-if="unpaidAndUnshipped.loading"
                  ></span>
                  <template v-else>
                    {{ unpaidAndUnshipped.count }}
                  </template>
                </span>
                <i class="bi bi-pencil-square"></i> 待付款待出貨
              </li>
              <li
                class="pointer hover position-relative"
                :class="{ active: currentPage === '已付款待出貨' }"
                @click="go('已付款待出貨')"
              >
                <span
                  class="tw-tips-badge position-absolute tw-end5"
                  :class="
                    paidAndUnshipped.count > 0
                      ? 'tw-tips-badge-danger'
                      : 'tw-tips-badge-secondary'
                  "
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-if="paidAndUnshipped.loading"
                  ></span>
                  <template v-else>
                    {{ paidAndUnshipped.count }}
                  </template>
                </span>
                <i class="bi bi-pencil-square"></i> 已付款待出貨
              </li>
              <li
                class="pointer hover position-relative"
                :class="{ active: currentPage === '未付款已出貨' }"
                @click="go('未付款已出貨')"
              >
                <span
                  class="tw-tips-badge position-absolute tw-end5"
                  :class="
                    unpaidAndShipped.count > 0
                      ? 'tw-tips-badge-danger'
                      : 'tw-tips-badge-secondary'
                  "
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-if="unpaidAndShipped.loading"
                  ></span>
                  <template v-else>
                    {{ unpaidAndShipped.count }}
                  </template>
                </span>
                <i class="bi bi-pencil-square"></i> 未付款已出貨
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '被併單' }"
                @click="go('被併單')"
              >
                <i class="bi bi-pencil-square"></i> 被併單
              </li>
              <li
                class="pointer hover"
                :class="{ active: currentPage === '已完成帳單' }"
                @click="go('已完成帳單')"
              >
                <i class="bi bi-pencil-square"></i> 已完成帳單
              </li>
            </ul>
          </div>
        </li>
        <li class="pointer hover text-center bg-light" @click="go('登出')">
          登出
        </li>
      </ul>
      <p class="sidebar-copy-right">©2022~2023優碼興科技股份有限公司</p>
    </div>
    <!-- sidebar toggle btn -->
    <div
      id="show-sidebar-btn"
      class="show-sidebar-btn hide-sidebar-btn d-flex align-items-center"
      @click="toggleSideBar('show')"
    >
      <span class="fw-bolder">{{ currentPage }}</span>
      <img src="@/assets/icon/angle-double-small-right.png" alt="" />
    </div>
    <!-- sidebar area -->
    <div id="sidebar-area" class="sidebar-area"></div>
    <!-- router view -->
    <div id="customer-content" class="customer-content">
      <div style="margin-top: 40px"></div>
      <router-view v-if="reloadRouterView"></router-view>
    </div>
  </div>
</template>

<script>
// components
import SelectStores from '@/components/sidebar/SelectStores.vue'
// api
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'

export default {
  components: { SelectStores },
  data() {
    return {
      currentPage: '',
      reloadRouterView: true,
      // 顯示哪個第二階層
      showWhichSecondClass: '',
      // data
      participantId: 0,
      slaveId: 0,
      myOrderList: [],
      myOrderQuantity: {
        loading: true,
        count: 0,
      },
      unpaidAndUnshipped: {
        loading: true,
        count: 0,
      },
      paidAndUnshipped: {
        loading: true,
        count: 0,
      },
      unpaidAndShipped: {
        loading: true,
        count: 0,
      }
    }
  },
  created() {
    this.checkCurrentPage()
    this.getParticipantId()
  },
  mounted() {
    this.toggleSideBar('close')
  },
  watch: {
    $route(to, from) {
      console.log(to)
      if (to.name === 'Login' || to.name === 'CustomerLogin' || to.name === 'CustomerInfo') return
      this.checkCurrentPage()
      // 如果更換賣場就更新畫面
      this.getParticipantId()
      console.log(to.params.storeId, from.params.storeId)
      if (to.params.storeId != from.params.storeId) {
        if (to.name === 'C_Shop' || to.name === 'C_OrderPage' || to.name === 'C_Overview') {
          this.$router.push({name: 'C_Shop'})
        } else {
          if (to.name === 'C_Bill') {
            this.$router.push({
              name: 'C_Bill',
              query: {
                status: from.query.status
              }
            })
          }
        }
        this.reloadRouterView = false
        this.$nextTick(() => {
          this.reloadRouterView = true
        })
      }
    },
  },
  methods: {
    // sidebar 切換
    toggleSideBar(status) {
      if (status === 'close') {
        $('#customer-sidebar').addClass('close-sidebar')
        $('#show-sidebar-btn').removeClass('hide-sidebar-btn')
        $('#sidebar-area').addClass('close-sidebar-area')
        $('#customer-content').addClass('content-width100')
        $('#overlay').removeClass('overlay')
      } else {
        this.getParticipantId()
        $('#customer-sidebar').removeClass('close-sidebar')
        $('#show-sidebar-btn').addClass('hide-sidebar-btn')
        $('#sidebar-area').removeClass('close-sidebar-area')
        $('#customer-content').removeClass('content-width100')
        $('#overlay').addClass('overlay')
      }
    },
    // 前往
    go(where) {
      switch (where) {
        case "個人頁面":
          this.$router.push({name: 'C_StoreLists'})
          this.toggleSideBar('close')
          break;
        case "賣場總覽":
          this.$router.push({name: 'C_Overview'})
          this.toggleSideBar('close')
          break;
        case "個人賣場":
          this.$router.push({name: 'C_Shop'})
          this.toggleSideBar('close')
          break;
        case "我的訂單":
          this.$router.push({name: 'C_MerchOrder'})
          this.toggleSideBar('close')
          break;
        case "帳單狀態":
          this.showWhichSecondClass === 'C_Bill' ? this.showWhichSecondClass = '' : this.showWhichSecondClass = 'C_Bill'
          break;
        case "待付款待出貨":
          this.$router.push({
            name: 'C_Bill',
            query: {
              status: 'unpaidAndUnshipped'
            }
          })
          this.toggleSideBar('close')
          break;
        case "已付款待出貨":
          this.$router.push({
            name: 'C_Bill',
            query: {
              status: 'paidAndUnshipped'
            }
          })
          this.toggleSideBar('close')
          break;
        case "未付款已出貨":
          this.$router.push({
            name: 'C_Bill',
            query: {
              status: 'unpaidAndShipped'
            }
          })
          this.toggleSideBar('close')
          break;
        case "被併單":
          this.$router.push({
            name: 'C_Bill',
            query: {
              status: 'merged'
            }
          })
          this.toggleSideBar('close')
          break;
        case "已完成帳單":
          this.$router.push({
            name: 'C_Bill',
            query: {
              status: 'finished'
            }
          })
          this.toggleSideBar('close')
          break;
        case "登出":
          this.$methods.delCookie('serverToken')
          this.$router.push({name: 'CustomerLogin'})
          break;
      }
    },
    // 檢查目前在哪一頁
    checkCurrentPage() {
      this.currentPage = ''
      switch (this.$route.name) {
        case "CustomerInfo":
          this.currentPage = '個人頁面'
          break;
        case "C_Overview":
          this.currentPage = '賣場總覽'
          break;
        case "C_Shop":
          this.currentPage = '個人賣場'
          break;
        case "C_MerchOrder":
          this.currentPage = '我的訂單'
          break;
        case "C_Bill":
          if (this.$route.query.status === 'unpaidAndUnshipped') {
            this.currentPage = '待付款待出貨'
          } else if (this.$route.query.status === 'paidAndUnshipped') {
            this.currentPage = '已付款待出貨'
          } else if (this.$route.query.status === 'unpaidAndShipped') {
            this.currentPage = '未付款已出貨'
          } else if (this.$route.query.status === 'merged') {
            this.currentPage = '被併單'
          } else if (this.$route.query.status === 'finished') {
            this.currentPage = '已完成帳單'
          }
          break;
      }
    },
    // 取得目前帳號的  participantId
    getParticipantId() {
      const result = parseTokenToGetTheParticipant(this.$route.params.storeId, this.$methods.getCookie('serverToken'))
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          this.participantId = res.data.id
          this.slaveId = res.data.slaves.length > 0 ? res.data.slaves[0].id : 0
          this.getMyOrderLists()
          this.getBillCount()
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
      })
    },
    // 取得我的訂單
    getMyOrderLists() {
      const vm = this
      const getMyOrderListsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.$methods.getCookie('serverToken')
      }
      let participantIds = [this.participantId]
      if (this.slaveId) participantIds.push(this.slaveId)
      const query = {"distinct":true,"columns":[{"columnName":"id","siteObjectType":26}],"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":26},"operator":{"operator":"=","type":1,"value":this.$route.params.storeId}},{"column":{"columnName":"buyerId","siteObjectType":26},"operator":{"operator":"=","type":3,"value":participantIds}},{"groups":[{"column":{"columnName":"quantity","siteObjectType":26},"operator":{"operator":"!=","type":1,"value":{"columnName":"allocatedQuantity","siteObjectType":26}}},{"column":{"columnName":"quantity","siteObjectType":26},"operator":{"operator":"!=","type":1,"value":"(select if(sum(billbody.Quantity) is null,0,sum(billbody.Quantity)) from billbody where billbody.MerchOrderId=merchorder.id)"}},{"column":{"columnName":"quantity","siteObjectType":26},"operator":{"operator":"!=","type":1,"value":"(select if(sum(billbody.Quantity) is null,0,sum(billbody.Quantity)) from billbody inner join billheader on billbody.BillHeaderId=billheader.id where billbody.MerchOrderId=merchorder.id and billheader.Paid=true)"}},{"column":{"columnName":"quantity","siteObjectType":26},"operator":{"operator":"!=","type":1,"value":"(select if(sum(billbody.Quantity) is null,0,sum(billbody.Quantity)) from billbody inner join billheader on billbody.BillHeaderId=billheader.id where billbody.MerchOrderId=merchorder.id and billheader.Shipped=true)"}}],"operator":2}],"operator":1},"siteObjectType":26,"sqlCommandType":1}
      const data = [
        {
          query: query,
          methods: '{getCheckOutQuantity,getPaymentQuantity,getShippingQuantity}'
        }
      ]
      this.myOrderQuantity = {
        loading: true,
        count: 0
      }
      this.myOrderList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getMyOrderListsApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.myOrderList = res.data[0].objects
            vm.asyncMyOrderCount()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 非同步計算我的訂單數量(數量加總)
    asyncMyOrderCount() {
      setTimeout(() => {
        this.myOrderList.forEach(item => {
          // 到貨狀態
          const arrivalStatus = item.quantity === item.allocatedQuantity
          // 結單狀態
          const checkOutStatus = item.quantity === item.checkOutQuantity
          // 付款狀態
          const paymentStatus = item.quantity === item.paymentQuantity
          // 出貨狀態
          const shippedStatus = item.quantity === item.shippingQuantity
          if (item.quantity > 0 && (!arrivalStatus || !checkOutStatus || !paymentStatus || !shippedStatus)) {
            this.myOrderQuantity.count ++
          }
        })
        this.myOrderQuantity.loading = false
      }, 1)
    },
    // 取得帳單狀態數量 (listLength 加總)
    getBillCount() {
      let query = ''
      // 待付款待出貨
      this.unpaidAndUnshipped = {
        loading: true,
        count: 0
      }
      let participantIds = [this.participantId]
      if (this.slaveId) participantIds.push(this.slaveId)
      query = {"distinct":true,"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":3,"value":participantIds}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":true,"type":4}},{"groups":[{"column":{"columnName":"paid","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}},{"column":{"columnName":"shipped","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}}],"operator":1}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
      this.getBillLists('unpaidAndUnshipped',query)
      // 已付款待出貨
      this.paidAndUnshipped = {
        loading: true,
        count: 0
      }
      query = {"distinct":true,"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":3,"value":participantIds}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":true,"type":4}},{"groups":[{"column":{"columnName":"paid","siteObjectType":27},"operator":{"operator":"=","type":1,"value":true}},{"column":{"columnName":"shipped","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}}],"operator":1}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
      this.getBillLists('paidAndUnshipped',query)
      // 未付款已出貨
      this.unpaidAndShipped = {
        loading: true,
        count: 0
      }
      query = {"distinct":true,"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":3,"value":participantIds}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":true,"type":4}},{"groups":[{"column":{"columnName":"paid","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}},{"column":{"columnName":"shipped","siteObjectType":27},"operator":{"operator":"=","type":1,"value":true}}],"operator":1}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
      this.getBillLists('unpaidAndShipped',query)
    },
    getBillLists(state,query) {
      const vm = this
      const getBillListsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.$methods.getCookie('serverToken')
      }
      const data = [
        {
          query: query,
          methods: '{}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillListsApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billLists = res.data[0].objects
            if (state === 'unpaidAndUnshipped') {
              vm.unpaidAndUnshipped = {
                loading: false,
                count: billLists.length
              }
            } else if (state === 'paidAndUnshipped') {
              vm.paidAndUnshipped = {
                loading: false,
                count: billLists.length
              }
            } else if (state === 'unpaidAndShipped') {
              vm.unpaidAndShipped = {
                loading: false,
                count: billLists.length
              }
            }
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
      
    }
  }
}
</script>